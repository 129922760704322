<template>
  <v-main>
    <v-container>
      <section class="section-1">
        <div class="d-sm-flex align-center">
          <div>
            <div>
              <h1 class="heading mb-3 peeps--font">
                <span class="heading-peeps peeps--color mr-3">Peeps</span>
                <span class="heading-portal">Portal</span>
              </h1>
            </div>

            <div>
              <p class="s1-p">
                Track and analyze your well water quality, septic system, energy
                usage, property taxes, and other aspects of your rural homestead
                in Truro.
              </p>
            </div>
            <div class="mt-6">
              <v-btn
                :to="$store.getters.auth ? '/parcels' : '/signup'"
                x-large
                rounded
                class="mt-3 mr-3"
                color="success"
                width="180"
              >
                Get Started
              </v-btn>
              <v-btn
                x-large
                width="180"
                rounded
                class="mt-3"
                color="#23272a"
                dark
                @click="showMore()"
              >
                Learn More <v-icon> mdi-chevron-down-circle </v-icon>
              </v-btn>
            </div>
          </div>
          <div style="max-width: 600px" class="image-container-1">
            <v-img
              v-if="$store.getters.auth"
              min-width="250"
              src="../assets/img/Home.svg"
            >
            </v-img>
            <v-img
              v-else
              min-width="250"
              src="../assets/img/Home-Logged-In.svg"
            >
            </v-img>
          </div>
        </div>
      </section>
      <section class="section section-2">
        <div class="d-sm-block d-flex justify-center">
          <div>
            <div class="d-sm-flex align-center justify-center flex-1">
              <div class="card-container-1 card-container">
                <v-card
                  :max-width="cardMaxWidth"
                  :min-height="cardMinHeight"
                  color="#a5b8ff"
                  class="card-1"
                >
                  <v-card-title> Features and Benefits </v-card-title>
                  <v-card-text>
                    The Peeps Portal can help you track and analyze your well
                    water quality, septic system, energy usage, property taxes,
                    and soon other aspects of your rural homestead in Truro. The
                    app is new in 2021 and is evolving as needs of the community
                    are becoming apparent.
                  </v-card-text>
                </v-card>
              </div>
              <div class="card-container-2 card-container">
                <v-card
                  :max-width="cardMaxWidth"
                  :min-height="cardMinHeight"
                  :color="colors['peeps-yellow']"
                  class="card-2"
                >
                  <v-card-title> Free to All of Truro </v-card-title>
                  <v-card-text>
                    The app is free to all Truro home/land owners who want to
                    better understand and manage their property. Responsible
                    stewardship by every resident serves the Truro community and
                    safeguards fragile natural resources we value and share.
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <div class="d-sm-flex justify-center flex-2">
              <div class="card-container-3 card-container">
                <v-card
                  :max-width="cardMaxWidth"
                  :min-height="cardMinHeight"
                  :color="colors['peeps-green']"
                  outlined
                  class="card-3"
                >
                  <v-card-title> Why Peeps? </v-card-title>

                  <v-card-text class="peeps--font">
                    My name is Peeps. I'm here to help you learn about your
                    environment in Truro, what affects it, and how you can help
                    measure it.
                    <div class="text-center">
                      <v-btn
                        rounded
                        class="text-capitalize mt-3 peeps--text"
                        @click="$vuetify.goTo('.section-3', { duration: 1500 })"
                      >
                        Learn More About Peeps
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section section-3">
        <div>
          <div class="text-center">
            <h3 class="peeps-features">
              Here are just some of the ways that Peeps can help you keep track
              of your home.
            </h3>
          </div>
          <div class="d-flex flex-wrap justify-space-around">
            <blob-feature
              :active="blob"
              :class="`blob-feature-${index}`"
              @click="showFeatureDetail"
              @hover="showFeatureDetail"
              v-for="(b, index) in blobs"
              :key="index"
              :value="b.feature"
            ></blob-feature>
          </div>
          <div>
            <v-card max-width="400" class="mx-auto" hover flat>
              <div class="image-container-feature">
                <v-img
                  class="solar-card"
                  src=" /gif/SolarPanelsSunMoving.GIF"
                  v-if="blob == 'solar'"
                ></v-img>
                <v-img
                  class="septic-card"
                  src="@/assets/img/SepticSystem.png"
                  v-if="blob == 'septic'"
                ></v-img>
                <v-img
                  class="hvac-card"
                  src="@/assets/img/hvac.svg"
                  v-if="blob == 'hvac'"
                ></v-img>
                <v-img
                  class="water-card"
                  src="@/assets/img/water.svg"
                  v-if="blob == 'water'"
                ></v-img>
                <v-img
                  class="tax-card"
                  src="@/assets/img/tax.svg"
                  v-if="blob == 'tax'"
                ></v-img>
              </div>
            </v-card>
          </div>
        </div>
      </section>
      <!-- <section class="section-4">
        <testimonials></testimonials>
      </section> -->
      <section class="section-5">
        <create-free-account></create-free-account>
      </section>
      <!-- <div class="footer-card">
        Support for the Peeps Portal comes in part from people like you, and
        from tax-deductible donations made to the Truro Environmental Defense
        Fund Inc, a 501(c)(3) non-profit corporation. If you would like to help,
        please mail a donation to PO Box 727, Truro MA 02666. Thank you.
      </div> -->
    </v-container>
  </v-main>
</template>

<script>
import CreateFreeAccount from '@/components/CreateFreeAccount'
import colors from '../design/_colors.scss'
import user from '@/mixins/user'
import { gsap } from 'gsap'
import User from '../services/user'
const u = new User()
export default {
  mixins: [user],
  components: {
    CreateFreeAccount
    // Testimonials: () => import('@/components/Testimonials')
  },
  metaInfo: {
    meta: [
      {
        name: 'description',
        content:
          'The Peeps Portal can help you track and analyze your well water quality, septic system, energy usage, property taxes, and soon other aspects of your rural homestead in Truro. The app is new in 2021 and is evolving as needs of the community are becoming apparent.'
      }
    ],
    title: 'Peeps Portal',
    titleTemplate: '%s'
  },
  data: () => ({
    cardMaxWidth: 300,
    cardMinHeight: 200,
    blobs: [
      { feature: 'water' },
      { feature: 'septic' },
      { feature: 'hvac' },
      { feature: 'solar' },
      { feature: 'tax' }
    ],
    blob: 'water',
    featureInterval: null
  }),
  computed: {
    colors() {
      return colors
    }
  },
  mounted() {
    this.animateTitle()
    this.animateCards()
    this.animateFeatures()
    this.setFeatureInterval()
  },
  methods: {
    showFeatureDetail(blob) {
      this.blob = blob
      this.clearFeatureInterval()
    },
    showMore() {
      this.$vuetify.goTo('.section-2', { duration: 1500 })
    },
    animateTitle() {
      gsap.from('.heading', {
        y: -90,
        duration: 1.5,
        opacity: 0,
        ease: 'power2'
      })

      gsap.from('.image-container-1', {
        x: 300,
        duration: 1,
        opacity: 0,
        ease: 'power2'
      })
    },
    animateCards() {
      let cardContainers = [
        { selector: '.card-1', from: -80 },
        { selector: '.card-2', from: 80 },
        { selector: '.card-3', from: -80 }
      ]
      cardContainers.forEach(card => {
        gsap.from(card.selector, {
          scrollTrigger: {
            trigger: card.selector,
            start: 'top bottom ',
            end: 'bottom bottom',
            scrub: 1
          },
          opacity: 0,
          x: card.from,
          duration: 0.9,
          ease: 'power2.inOut'
        })
      })
    },
    animateFeatures() {
      for (let i = 0; i < 5; i++) {
        let selector = `.blob-feature-${i}`
        gsap.from(selector, {
          scrollTrigger: {
            trigger: selector,
            start: 'top bottom',
            scrub: 1
          },
          // x: !(i & 1) ? -100 : 100,
          // x: i == 2 ? 0 : i < 3 ? -100 : 100,
          // y: i == 2 ? -50 : 0,
          y: -60,
          duration: 1,
          opacity: 0,
          ease: 'power4'
        })
      }
    },
    changeFeatureBlob() {
      let i
      for (i = 0; i < this.blobs.length; i++) {
        if (this.blob == this.blobs[i].feature) {
          break
        }
      }
      if (i == this.blobs.length - 1) {
        i = 0
      } else {
        i = i + 1
      }
      this.blob = this.blobs[i].feature
    },
    setFeatureInterval() {
      this.featureInterval = setInterval(() => {
        this.changeFeatureBlob()
      }, 3000)
    },
    clearFeatureInterval() {
      clearInterval(this.featureInterval)
    }
  },
  beforeDestroy() {
    this.clearFeatureInterval()
  }
}
</script>

<style scoped lang="sass">
@import "../design/_colors.scss"
.create-account
  margin-top: 3rem

.card-container
  margin-top: 1rem

.section-1
  margin-top: 10vh
  min-height: 90vh

@media (max-width: 600px)
  .section-1
    margin-top: 3vh
    min-height: 97vh

.section-2
  min-height: 100vh

.section-3
  margin-top: 10vh
  min-height: 80vh

@media (min-width: 790px)
  .section-4
    margin-top: -7rem !important

.section-5
  margin-top: -2rem

.img-1
  margin-top: 1rem

.heading
  margin: 0
  padding: 0
  border: 0
  outline: 0
  font-style: inherit
  vertical-align: baseline
  font-family: Comic Sans MS, cursive, sans-serif
  font-weight: 800
  line-height: 95%
  text-transform: uppercase
  text-rendering: geometricPrecision
  font-size: 56px

@media (min-width: 600px)
  .card-2
    margin-top: 9rem
    margin-left: 2rem

  .card-3
    margin-top: 2rem
    margin-right: 7rem

  .img-1
    margin-top: 5rem

@media (max-width: 960px)
  .section-1-col-1
    margin: 50px

@media (max-width: 1271px)
  .heading
    font-size: 36px

@media (min-width: 600px) and (max-width: 915px)
  .image-container-1
    max-width: 400px !important

  .heading
    font-size: 26px

.s1-p
  margin-top: 10px
  white-space: initial
  font-size: 1.4rem
  line-height: 2rem

.footer-card
  margin-top: 4rem
  padding: 2rem
  background: $color-peeps-light-yellow
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37)
  backdrop-filter: blur(3.5px)
  -webkit-backdrop-filter: blur(3.5px)
  border-radius: 10px
  border: 1px solid rgba(255, 255, 255, 0.18)

.peeps--color
  color: #448700 !important

.heading-portal
  color: #1d3604 !important

.wave-section-1
  z-index: 0
  position: absolute
  bottom: 0

.image-container-feature
  max-width: 400px

.peeps-features
  --not-quite-black-rgb: 35, 39, 42
  text-rendering: optimizeLegibility
  -webkit-box-direction: normal
  color: var(--not-quite-black)
  margin: 0
  padding: 0
  border: 0
  outline: 0
  font-weight: inherit
  font-style: inherit
  font-family: inherit
  vertical-align: baseline
  font-size: 1.4rem
  line-height: 1.625
  margin-top: 24px

@media (max-width:450px)
  .peeps-features
    font-size: 1rem
</style>
